import { useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { navigate } from "gatsby"
const { isNil } = require("lodash")

export const useUserServices = serviceName => {
  const { isAuthenticated, isLoading, user } = useAuth0()
  const [state, setState] = useState({
    loading: true,
    hasService: null,
  })

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      const user_services = user["https://api.codelinaro.org/services"] || {}
      if (
        serviceName in user_services &&
        "status" in user_services[serviceName] &&
        user_services[serviceName]["status"] === "active"
      ) {
        setState({
          hasService: true,
          loading: false,
        })
      } else {
        setState({
          hasService: false,
          loading: false,
        })
      }
    } else {
      setState({
        hasService: false,
        loading: false,
      })
    }
  }, [user, isLoading, isAuthenticated, serviceName])

  return state
}

export const useUserOrgs = () => {
  const { user, isAuthenticated, isLoading } = useAuth0()
  const [state, setState] = useState({
    loading: true,
    orgs: [],
    currentOrg: {
      index: null,
      name: null,
      role: {},
    },
  })

  useEffect(() => {
    if (!isLoading) {
      const url = "https://api.codelinaro.org/"
      let currentOrg = {
        index: null,
        name: null,
        role: {
          currentRole: null,
          isUserAdmin: false,
          isProjectAdmin: false,
          isSystemAdmin: false,
          isSuperAdmin: false,
        },
      }
      let orgs = []
      if (isAuthenticated) {
        if ("super-admin" === user[url + "0/role"]) {
          localStorage.removeItem(user[url + "username"])
          orgs = [
            {
              index: null,
              name: null,
              role: {
                currentRole: user[url + "0/role"],
                isUserAdmin: true,
                isProjectAdmin: true,
                isSystemAdmin: true,
                isSuperAdmin: true,
                isExternalProjectContributor: true,
              },
            },
          ]
          currentOrg = orgs[0]
        } else {
          let index = 0
          while (true) {
            const role = user[url + index + "/role"]
            if (isNil(role)) {
              break
            }
            orgs.push({
              index: index.toString(),
              name: user[url + index + "/parentOrganization"],
              role: {
                currentRole: role,
                isUserAdmin: ["users-admin", "system-admin"].includes(role),
                isProjectAdmin: ["projects-admin", "system-admin"].includes(role),
                isSystemAdmin: ["system-admin"].includes(role),
                isSuperAdmin: false,
                isExternalProjectContributor: ["contributor"].includes(role),
              },
            })
            index++
          }
          currentOrg = orgs.find(o => o.index === localStorage.getItem(user[url + "username"]))
          if (!currentOrg) {
            // After redirecting to the page, set loading to false, while setting the redirected page, loading is still true
            currentOrg = orgs[0]
            localStorage.setItem(user[url + "username"], "0")
            navigate("/dashboard/")
          }
        }
      }
      setState({
        loading: false,
        orgs,
        currentOrg,
      })
    }
  }, [user, isLoading, isAuthenticated])

  return state
}
