import React, { Component, useContext, useRef, useEffect, useState } from "react"
import { Tooltip as MateriaUiTooltip, makeStyles, MenuItem, Select, Radio } from "@material-ui/core"
import { Modal, ModalHeader, ModalBody, ModalFooter, Label, FormText, FormGroup, Input } from "reactstrap"
import {
  Button,
  Form,
} from "reactstrap"

import { fetch_get, fetch_post, fetch_put } from "../../../utils/fetch"
import * as Sentry from "@sentry/gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import Chart from "react-apexcharts"
import { useUserOrgs } from "../../../hooks/use-user"


function ModalIndex(props) {
  const {
    currentOrg: { index },
  } = useUserOrgs()
  const {
    loading,
    currentOrg: {
      role: { isProjectAdmin, isUserAdmin, isSystemAdmin, isSuperAdmin },
    },
  } = useUserOrgs()
  const useStyles = makeStyles(() => ({
    modal: {
      width: 1000,
    },
    form: {
      maxHeight: "calc(100vh - 200px)",
      overflowY: "auto",
      margin: "0 3rem 1rem 3rem",
    },
  }))
  const [info, setInfo] = useState(props.row && JSON.stringify(props.row) != "{}" ? { ...props.row } : {
    scope: "Git",
    depth: "All",
    project_id: null,
    org_id: 0,
    group: "",
  })
  const [modal, setModal] = useState(props.modal || false)
  const [infoTwo, setInfoTwo] = useState(props.row && JSON.stringify(props.row) != "{}" ? {
    usage: props.row.usage,
    eMailStatus: true,
    subscribers: props.row.subscribers,
  } : {
    usage: "",
    eMailStatus: true,
    subscribers: "",
  })
  const { getAccessTokenSilently, user } = useAuth0()
  const [ipsData, setIpData] = useState([])
  const [processAdvice, setProcessAdvice] = useState([])
  const [visibility, setVisibility] = useState(props.row && JSON.stringify(props.row) != "{}" ? props.row.visibility : "Parent Organization")
  const [processMax, setProcessMax] = useState("")
  const [processValue, setProcessValue] = useState("")
  const [workGroup, setWorkGroups] = useState([])
  const [scale, setScale] = useState("")
  const [orgNewList, setOrgNewList] = useState([])
  const [userOrg, setUserOrg] = useState("")
  var [loadStatus, setLoadStatus] = useState({ loading: false, error: false, errorMessage: null })

  const classes = useStyles()
  useEffect(() => {
    if (info.depth == "General") {
      setInfo({ ...info, group: info.depth })
    } else {
      if (info.depth == "All") {
        console.log("+++++++++++++++++++++++++====")
        setInfo({ ...info, group: "IP" })
      } else {
        console.log("--------------------------")
        setInfo({ ...info, group: "IP" })
      }
    }
    if (!infoTwo.eMailStatus) {
      setInfoTwo({ ...infoTwo, eMailStatus: true })
    }
    console.log(props.row, "props.row++", info)
  }, [modal])
  useEffect(() => {
    if (modal) {
      getIP()
      visibility == "Managed Group" && get_workgroup_promise()
      visibility == "Parent Organization" && get_organizations_promise()
    }
  }, [modal, visibility])
  useEffect(() => {
    const userOrg = index
      ? user[`https://api.codelinaro.org/${index}/organization`]
      : user["https://api.codelinaro.org/0/organization"]
    setUserOrg(userOrg)
    console.log(userOrg, "userOrg")
  }, [visibility])
  // useEffect(() => {
  //   // drawBar()
  // }, [processValue, processMax])
  useEffect(() => {
    onCheck()
    // drawBar()
  }, [info, visibility])
  var [chartSettings, setChartSettings] = useState({})
  const drawBar = () => {
    let showtip = ""
    if (info.depth == "General") {
      showtip = "General"
    } else {
      showtip = info.depth
    }
    var cs = {
      series: [{
        name: "IP:" + showtip,
        data: [Number(processValue)],
      }, {
        name: "remainder",
        data: [Number(processMax) - Number(processValue)],
      }],
      options: {
        chart: {
          type: "bar",
          height: 150,
          stacked: true,
          stackType: "100%",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 10,
          },
        },

        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        title: {
          text: "",
          show: false,
        },
        xaxis: {
          categories: [""],
          show: false,
          labels: {
            show: false,
            formatter: function(val) {
              return val
            },
          },
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val + scale
            },
          },
        },
        fill: {
          opacity: 1,

        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
    }

    setChartSettings(cs)
  }

  const get_organizations_promise = () => {
    let url = "ui/organizations?includeorgid=1&includechildorgid=1&ui=1"
    getAccessTokenSilently()
      .then(accessToken => fetch_get(url, accessToken))
      .then(response => {
        const parListArr = Object.values(response)
        setOrgNewList(parListArr)
        if (props.row.id && props.row !== {}) {

        } else {
          setInfo({ ...info, org_id: parListArr[0].orgid })
        }
      })
  }
  const onSubmit = () => {
    setLoadStatus({ loading: true, error: false, errorMessage: null })
    let url = "admin/data-transfer-threshold/systemwide"
    if (visibility == "Managed Group") {
      url = `admin/data-transfer-threshold/managed_group`
    }
    if (visibility == "Parent Organization") {
      url = "admin/data-transfer-threshold/parent-organization"
    }
    let obj = Object.assign(info, { ...infoTwo }, { visibility: visibility })

    if (obj.depth == " ") {
      alert("Please select an IP")
      setLoadStatus({ loading: false, error: false, errorMessage: null })
    } else {
      if (obj.group == "General") {
        obj.depth = "General"
        delete obj.group
      } else {
        delete obj.group
      }
      if (visibility == "Managed Group") {
        delete obj.org_id
        if (obj.project_id == "") {
          alert("Please select a project")
          return false
        }
      } else if (visibility == "Parent Organization") {
        delete obj.project_id
        if (obj.org_id == "" && obj.org_id !== null) {
          alert("Please select an organization")
          return false
        } else {
          obj.org_id = Number(obj.org_id)
        }
      } else {
        delete obj.org_id
        delete obj.project_id
      }
      delete obj.visibility
      delete obj.eMailStatus
      delete obj.org_name
      delete obj.project_slug
      delete obj.created
      delete obj.updated_date
      //editor
      if (props.row.id && props.row !== {}) {
        let id = props.row.id
        delete obj.id
        obj["visibility"] = visibility
        obj.usage = Number(Number(obj.usage).toFixed(0))
        getAccessTokenSilently()
          .then(accessToken => fetch_put(`admin/data-transfer-threshold/${id}`, obj, accessToken))
          .then(response => {
            props.toggle()
            setLoadStatus({ loading: false, error: false, errorMessage: null })
          })
          .catch(error => {
            setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
            console.log(`Error while getting all licenses: ${error}`)
          })
      } else {
        obj.usage = Number(Number(obj.usage).toFixed(0))
        getAccessTokenSilently()
          .then(accessToken =>
            fetch_post(url, obj, accessToken)
              .then(response => {
                props.toggle()
                setLoadStatus({ loading: false, error: false, errorMessage: null })
              })
              .catch(error => {
                setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
                Sentry.captureException(error)
                console.log(`Error while getting workgroup links list: ${error}`)
              }),
          )
          .catch(error => {
            Sentry.captureException(error)
            setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
            console.log(`Error while getting acdcess token: ${error}`)
          })
      }
    }
  }
  const onCheck = async (event) => {

    setLoadStatus({ loading: true, error: false, errorMessage: null })
    let url = `admin/data-transfer-threshold/systemwide?scope=${info.scope ? info.scope : "Git"}&depth=${info.depth ? info.depth : "All"}`
    if (visibility == "Managed Group" && info.scope && info.depth) {
      url = `admin/data-transfer-threshold/managed_group?scope=${info.scope ? info.scope : "Git"}&depth=${info.depth ? info.depth : "All"}&project_id=${info.project_id}`
    }
    if (visibility == "Parent Organization" && info.scope && info.depth) {
      url = `admin/data-transfer-threshold/parent-organization?scope=${info.scope}&depth=${info.depth}&org_id=${info.org_id}`
    }
    await getAccessTokenSilently()
      .then(accessToken =>
        fetch_get(url, accessToken)
          .then(response => {

            if (response && response.user_data_bytes) {
              setProcessValue(response.user_data_bytes)
            }
            if (response && response.total_data_bytes) {
              setProcessMax(response.total_data_bytes)
            }
            if (response && response.threshold && response.threshold.length > 0) {
              setProcessAdvice(response.threshold[0].q3)
              setInfoTwo({ ...infoTwo, usage: response.threshold[0].q3 })
            }
            if (response && response.scale) {
              setScale(response.scale)
            }
            setLoadStatus({ loading: false, error: false, errorMessage: null })
          })
          .catch(error => {
            setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
            console.log(`Error while getting workgroup links list: ${error}`)
            return
          }),
      )
      .catch(error => {
        setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
        console.log(`Error while getting acdcess token: ${error}`)
      })
    await getAccessTokenSilently()
      .then(accessToken =>
        fetch_get(`admin/data-transfer-threshold/usage?scope=${info.scope ? info.scope : "Git"}&depth=${info.depth ? info.depth : "All"}&visibility=${visibility}${visibility == "Managed Group" ? "&project_id=" + info.project_id : ""}${visibility == "Parent Organization" ? "&org_id=" + info.org_id : ""}`, accessToken)
          .then(response => {
            if (response.usage !== 0) {
              setInfoTwo({ ...infoTwo, usage: response.usage })
            }
          })
          .catch(error => {
            console.log(`Error while getting workgroup links list: ${error}`)
            return
          }),
      )
      .catch(error => {
        console.log(`Error while getting acdcess token: ${error}`)
      })
  }
  // get IPs
  const getIP = async () => {
    await getAccessTokenSilently()
      .then(accessToken =>
        fetch_get(`admin/data-transfer-threshold/all-ips`, accessToken)
          .then(response => {
            if (response.length > 0) {
              setIpData(response)
            }
          })
          .catch(error => {
            console.log(`Error while getting workgroup links list: ${error}`)
            return
          }),
      )
      .catch(error => {
        Sentry.captureException(error)
        console.log(`Error while getting acdcess token: ${error}`)
      })
  }
  const ipData = () => {
    return (
      <>
        {
          ipsData.length > 0 && ipsData.map(ele => {
            return <option key={ele}>{ele}</option>
          })
        }
      </>
    )
  }
  const infoChange = e => {
    console.log(e.name, e.value)
    setInfo({ ...info, [e.name]: e.value })
  }
  const infoTwoChange = e => {
    setInfoTwo({ ...infoTwo, [e.name]: e.value })
  }
  let url = "projects"
  const get_workgroup_promise = () => {
    getAccessTokenSilently()
      .then(accessToken => fetch_get(url, accessToken))
      .then(response => {
        setWorkGroups(response.data)
        if (props.row.id && props.row !== {}) {

        } else {
          setInfo({ ...info, project_id: response.data[0].projectId })
        }
      })
  }
  const handleVisibility = event => {
    setVisibility(event.target.value)
  }
  const fileModalRender = () => {
    return (
      <>
        <Form method="POST" className={classes.form}>
          <Label htmlFor="visibility">
            Select the visibility
          </Label>
          <FormGroup>
            <Select value={visibility || ""} onChange={handleVisibility}>
              {isSuperAdmin && <MenuItem value="Systemwide">Systemwide</MenuItem>}
              <MenuItem value="Managed Group">Managed Group</MenuItem>
              <MenuItem value="Parent Organization">Parent Organization</MenuItem>
            </Select>
          </FormGroup>
          <Label htmlFor="scope">
            Select the scope
          </Label>
          <FormGroup>
            <Input
              id="scope"
              name="scope"
              type="select"
              value={info.scope || "Git"}
              onChange={event => infoChange(event.target)}
            >
              <option>Git</option>
              <option>Artifactory</option>
              <option>Bundles</option>
              <option>All</option>
            </Input>
          </FormGroup>
          <Label htmlFor="group">
            Group by
          </Label>
          <FormGroup>
            <Input
              id="group"
              name="depth"
              type="select"
              value={info.depth == "General" ? "General" : "IP" || ""}
              onChange={event => infoChange(event.target)}
            >
              <option>IP</option>
              <option>General</option>
            </Input>
          </FormGroup>
          {info.depth !== "General" && <>
            <Label htmlFor="depthType">
              Select all IPs or single IP
            </Label>
            <FormGroup>
              <Input
                id="depthType"
                name="depth"
                type="select"
                value={info.depth == "All" ? info.depth : "Single IP" || ""}
                onChange={event => infoChange(event.target)}
              >
                <option>All</option>
                <option>Single IP</option>
              </Input>
            </FormGroup>
          </>
          }
          {info.depth !== "General" && info.depth !== "All" && <>
            <Label htmlFor="depth">
              Select IP
            </Label>
            <FormGroup>
              <Input
                id="depth"
                name="depth"
                type="select"
                value={info.depth || ""}
                onChange={event => infoChange(event.target)}
              >
                <option></option>
                {ipData()}
              </Input>
            </FormGroup>
          </>}

          {/*Project*/}
          {visibility == "Managed Group" && <FormGroup>
            <Label htmlFor="project_id">Project</Label>
            <Input
              type="select"
              name="project_id"
              id="project_id"
              value={info.project_id}
              onChange={event => infoChange(event.target)}
            >
              <option></option>
              {workGroup.map((p, index) => (
                <option value={p.projectId} key={p.projectId}>
                  {p["projectSlug"]}
                </option>
              ))}
            </Input>
          </FormGroup>}
          {/*Organization*/}
          {visibility == "Parent Organization" && <FormGroup>
            <Label htmlFor="org_id">Organization</Label>
            <Input
              type="select"
              name="org_id"
              id="org_id"
              value={info.org_id}
              onChange={event => infoChange(event.target)}
            >
              {isSuperAdmin && <option></option>}
              {orgNewList.map((p, index) => (
                <option value={p.orgid} key={p.orgid}>
                  {p["org_key"]}
                </option>
              ))}
            </Input>
          </FormGroup>}
        </Form>

        <Form className={classes.form}>
          <FormGroup>
            <div style={{ width: 600 }}>
              <span htmlFor="usage" style={{ marginRight: 10 }}>
                Trigger@
              </span>
              <Input
                id="usage"
                name="usage"
                type="number"
                value={infoTwo.usage ? infoTwo.usage : processAdvice || 0}
                onChange={event => infoTwoChange(event.target)}
                style={{ display: "inline-block", width: 100, marginRight: 10 }}
              />
              <span style={{ marginRight: 10 }}>GB</span>
              <div style={{
                display: "inline-block", border: "1px solid transparent",
                lineHeight: "15px",
                position: "relative",
                top: "8px",
              }}>
                <div>
                  <span
                    style={{ color: "rgb(107 107 107)", fontSize: "14px" }}>Recommended value: {processAdvice}</span>
                </div>
                <div>
                  <span
                    style={{
                      color: "rgb(107 107 107)",
                      fontSize: "14px",
                    }}>Calculation: 75th percentile of total bytes</span>
                </div>

              </div>


            </div>
          </FormGroup>
          {/*<Chart*/}
          {/*  options={chartSettings.options}*/}
          {/*  series={chartSettings.series}*/}
          {/*  type="bar"*/}
          {/*  width="100%"*/}
          {/*  height="200px"*/}
          {/*/>*/}
          <FormGroup check>
            <Input type="checkbox"
                   id="eMailStatus"
                   name="eMailStatus"
                   defaultChecked
                   onChange={event => {
                     infoTwoChange({ name: "eMailStatus", value: !infoTwo.eMailStatus })
                   }} />
            {" "}
            <Label check>
              Separate multiple email with commas
            </Label>
          </FormGroup>
          {infoTwo.eMailStatus && <FormGroup>
            <Label htmlFor="exampleEmail">
              Email
            </Label>
            <Input
              id="subscribers"
              name="subscribers"
              value={infoTwo.subscribers}
              onChange={event => infoTwoChange(event.target)}
              type="email"
            />
          </FormGroup>}
          <Button onClick={onSubmit}>
            Save
          </Button>
        </Form>
      </>
    )
  }
  return (
    <Modal isOpen={modal} toggle={props.toggle} style={{ width: "1000px", maxWidth: 1000 }}{...props}>
      <ModalHeader toggle={props.toggle}>Set Threshold Notifications</ModalHeader>
      <ModalBody>
        {loadStatus.loading && !loadStatus.error && (
          <>
            <div className="spinner-grow spinner-grow-sm" role="status"></div>
            Loading...
          </>
        )}
        {!loadStatus.loading && !loadStatus.error && (
          fileModalRender()
        )}
      </ModalBody>
    </Modal>

  )
}

export default ModalIndex
